<template>
  <div class="installationDocumentationList">
    <Portlet
      :title="$t('staticResourceUploaderComponent.files')"
      icon="image"
    >
      <LoadingPlaceholder v-if="loading" />
      <template v-else>
        <table
          v-if="files != null"
          class="defaultTable"
        >
          <colgroup>
            <col width="150">
            <col width="150">
            <col
              class="hide-down-lg"
              width="75"
            >
            <col
              class="hide-down-xl"
              width="150"
            >
            <col>
            <col
              class="hide-down-sm"
              width="100"
            >
            <col
              class="hide-down-sm"
              width="150"
            >
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('type') }}</th>
              <th>{{ $t('name') }}</th>
              <th class="hide-down-lg">
                {{ $t('size') }}
              </th>
              <th class="hide-down-xl">
                {{ $t('date') }}
              </th>
              <th>{{ $t('path') }}</th>
              <th class="hide-down-sm">
                {{ $t('preview') }}
              </th>
              <th class="hide-down-sm">
                {{ $t('options') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(file, fileIndex) in files"
              :key="`imageUploadImage-${ fileIndex }`"
            >
              <td>{{ file.path.includes('/scematic/') ? 'Scematic' : 'Network Overview' }}</td>
              <td>{{ file.name }}</td>
              <td
                v-tooltip="`${ file.size } bytes`"
                class="hide-down-lg"
              >
                {{ numberConversionAdapter_bytes(file.size).bytes }} {{ numberConversionAdapter_bytes(file.size).unit }}
              </td>
              <td
                v-tooltip="dateTime_fromNow(file.lastChangeDate)"
                class="hide-down-xl"
              >
                {{ dateTime_dateTime(file.lastChangeDate) }}
              </td>
              <td>
                <a
                  :href="`${cloudPath}${ file.path }`"
                  target="_blank"
                  class="cursor-pointer d-inline-block"
                >{{ file.path }}</a>
              </td>
              <td class="align-middle text-center hide-down-sm">
                <img
                  v-if="file.path.includes('.png') || file.path.includes('.jpg') || file.path.includes('.jpeg')"
                  :src="`${cloudPath}${ file.path }`"
                  :alt="file.name"
                  class="previewImage"
                >
                <font-awesome-icon
                  v-else-if="file.path.includes('.pdf')"
                  class="placeholderIcon"
                  icon="folder"
                />
              </td>
              <td class="hide-down-sm">
                <button
                  class="btn btn-sm btn-block btn-danger"
                  @click="deleteResource(file.name)"
                >
                  <font-awesome-icon
                    class="mr-2"
                    icon="trash"
                  />
                  <span>{{ $t('delete') }}</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else>
          {{ $t('noDataAvailable') }}
        </p>
      </template>
    </Portlet>
  </div>
</template>

<script>
import numberConversionAdapter from "@/adapter/numberConversionAdapter.js";
import { dateTimeMixin } from "@/mixins/dateTimeMixin.js";
export default {
  name: "InstallationDocumentationList",
  mixins: [dateTimeMixin, numberConversionAdapter],
  props: {
    installationId: {
      type: String,
      required: true
    },
    documentationType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      files: null,
      installation: null
    };
  },
  computed: {
    cloudPath () {
      if (this.installation != null) {
        if (this.installation.country == "China") {
          return "https://skiline-china.oss-cn-zhangjiakou.aliyuncs.com/";
        } else {
          return "https://skiline.s3-eu-west-1.amazonaws.com/";
        }
      }
      return "https://skiline.s3-eu-west-1.amazonaws.com/";
    }
  },
  created () {
    this.getInstallation();
    this.getData();
  },
  methods: {
    getData () {
      this.loading = true;
      this.axios
        .get(`/StaticResource/Get/${ this.installationId }?documentationType=${this.documentationType}`)
        .then(response => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.files = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getInstallation () {
      this.axios
        .get("/Installation/Get?id=" + this.installationId)
        .then(response => {
          this.installation = response.data;
        });
    },
    deleteResource (imageName) {
      this.axios
        .delete(`/StaticResource/Delete/${this.installationId}/${imageName}`)
        .then(response => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 200) {
            this.$snotify.success(
              this.$t("staticResourceUploaderComponent.fileDeletedMessage"),
              this.$t("staticResourceUploaderComponent.fileDeletedTitle")
            );
          }
        });
    }
  }
};
</script>

<style>
.installationDocumentationList .previewImage {
  width: 100%;
  height: auto;
  display: block;
}
.installationDocumentationList .placeholderIcon {
  font-size: 2rem;
}
</style>
